<template>
  <v-main>
    <v-container class="my-4">
      <v-card>
        <v-card-title class="text-h4">Jahresupdate</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="col col-12 col-md-6">
              <div class="mb-1">Person 1:</div>
              <v-select :items="customersSelectList" v-model="primaryCustomerId" dense clearable solo hide-details
                        class="mb-3"></v-select>
              <span class="customer-label">Geburtstag:</span>
              <span class="customer-value">{{ primaryCustomer["geburtsdatum"] | formatDate }}</span>
            </v-col>
            <v-col class="col col-12 col-md-6">
              <div class="mb-1">Person 2:</div>
              <v-select outlined :items="customersSelectList" v-model="secondaryCustomerId" dense clearable hide-details
                        class="mb-3"></v-select>
              <span class="customer-label">Geburtstag:</span>
              <span class="customer-value">{{ secondaryCustomer["geburtsdatum"] | formatDate }}</span>
            </v-col>
          </v-row>
          <div class="d-flex align-baseline mt-2">
            Adresse:
            <div class="customer-value">{{ primaryCustomer | address }}</div>
          </div>
          <div class="d-flex align-baseline mt-4">
            Bank:
            <div class="customer-value">{{ primaryCustomer["bank"] }}</div>
          </div>
          <div class="d-flex align-baseline mt-2">
            IBAN:
            <div class="customer-value">{{ primaryCustomer["iban"] }}</div>
          </div>
          <div class="d-flex align-baseline mt-2">
            BIC:
            <div class="customer-value">{{ primaryCustomer["bic"] }}</div>
          </div>
          <v-radio-group v-model="customerType" hide-details label="Jahresupdategebühr:">
            <v-radio label="SINGLE-HAUSHALT 75,00€" value="single"></v-radio>
            <v-radio label="AB 2-PERSONEN-HAUSHALT 90,00€" value="multi"></v-radio>
            <v-radio label="JAHRESCOACHING 0,00€" value="coaching"></v-radio>
            <v-radio label="STUDENT / AZUBI 0,00€" value="student"></v-radio>
          </v-radio-group>
          <div class="d-flex flex-wrap align-baseline mt-3 mb-3">
            <div class="text-h6 mr-3">Rechnungsnummer:</div>
            <v-text-field v-model="nextInvoiceNumber" dense outlined hide-details class="invoice-number-input"
                          style="max-width: 300px;"></v-text-field>
          </div>
          <div class="mt-6">
            <v-btn color="primary" class="mr-3 mb-2" :disabled="!allowGeneratePdf" :loading="jahresupdateInProgress"
                   @click="generateJahresupdatePdf">
              Jahresupdate PDF
            </v-btn>
            <v-btn color="primary" class="mr-2 mb-2" :disabled="!allowGeneratePdf" :loading="rechnungInProgress"
                   @click="generateRechnungPdf">
              Rechnung PDF
            </v-btn>
          </div>
          <div class="text-h6 mt-4">Coaching</div>
          <div class="mt-2">
            <v-btn color="primary" class="mr-3 mb-2" :disabled="!this.primaryCustomerId"
                   @click="generateDienstleistungsvertragPdf">
              Dienstleistungsvertrag PDF
            </v-btn>
            <v-btn color="primary" class="mr-2 mb-2" :disabled="!this.primaryCustomerId"
                   @click="generateCoachingRechnungPdf">
              Coaching Rechnung PDF
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import moment from "moment";

export default {
  name: "Jahresupdate",
  data: () => ({
    customers: [],
    customersDict: {},
    customersSelectList: [],
    primaryCustomerId: null,
    secondaryCustomerId: null,
    customerType: null,
    invoices: [],
    nextInvoiceNumber: "",
    jahresupdateInProgress: false,
    rechnungInProgress: false
  }),
  computed: {
    primaryCustomer() {
      if (this.primaryCustomerId) {
        return this.customersDict[this.primaryCustomerId];
      } else {
        return {};
      }
    },
    secondaryCustomer() {
      if (this.secondaryCustomerId) {
        return this.customersDict[this.secondaryCustomerId];
      } else {
        return {};
      }
    },
    allowGeneratePdf() {
      return this.primaryCustomerId && this.customerType;
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      }
    },
    address(customer) {
      if (customer.id) {
        return `${customer["strasse"]} ${customer["hausnummer"]}, ${customer["plz"]} ${customer["ort"]}`;
      }
    }
  },
  watch: {
    primaryCustomerId() {
      this.customerType = null;
      this.secondaryCustomerId = null;
    },
    secondaryCustomerId(newId) {
      if (newId) {
        this.customerType = "multi";
      } else {
        this.customerType = null;
      }
    }
  },
  created() {
    this.fetchCustomers();
    this.fetchInvoices();
  },
  methods: {
    fetchCustomers() {
      this.$store.dispatch("getRequestPromise", "roy-customers").then(response => {
        this.customers = response;
        this.customersDict = this.customers.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
        this.customersSelectList = this.customers.map(customer => {
          return {
            text: customer["name"] + ", " + customer["vorname"],
            value: customer.id
          };
        });
        // sort by name
        this.customersSelectList.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
      });
    },
    fetchInvoices() {
      this.$store.dispatch("getRequestPromise", "invoices/summary").then(response => {
        this.invoices = response["invoices"];
        // pad next invoice number with leading zeros, so that it becomes 4 digits long
        this.nextInvoiceNumber = String(response["nextInvoiceNumber"]).padStart(4, "0");
      });
    },
    generatePdf(type, apiUrl, fileNamePrefix) {
      return new Promise((resolve, reject) => {
        this[`${type}InProgress`] = true;
        const data = {
          primaryCustomerId: this.primaryCustomerId,
          secondaryCustomerId: this.secondaryCustomerId,
          customerType: this.customerType,
          invoiceNumber: this.nextInvoiceNumber
        };
        this.$store
            .dispatch("postRequestPromise", [apiUrl, data])
            .then(fileName => {
              const newFileName = `${fileNamePrefix}_${this.primaryCustomer["vorname"]}_${this.primaryCustomer["name"]}.pdf`;
              this.$store
                  .dispatch("downloadFilePromise", ["file-generation/admin-download/" + fileName, newFileName])
                  .finally(() => {
                    this[`${type}InProgress`] = false;
                    resolve();
                  });
            })
            .catch(error => {
              this[`${type}InProgress`] = false;
              reject(error);
            });
      });
    },
    generateJahresupdatePdf() {
      this.generatePdf("jahresupdate", "roy-customers/pdf/jahresupdate", "Jahresupdate");
    },
    generateRechnungPdf() {
      this.generatePdf("rechnung", "roy-customers/pdf/rechnung", "Rechnung")
          .then(() => {
            this.fetchInvoices();
          });
    },
    generateDienstleistungsvertragPdf() {
      this.generatePdf("dienstleistungsvertrag", "roy-customers/pdf/dienstleistungsvertrag", "Dienstleistungsvertrag");
    },
    generateCoachingRechnungPdf() {
      this.generatePdf("coachingRechnung", "roy-customers/pdf/coaching-rechnung", "Coaching-Rechnung")
          .then(() => {
            this.fetchInvoices();
          });
    }
  }
};
</script>

<style>
.invoice-number-input input {
  font-family: monospace;
  letter-spacing: 0.1em;
  font-size: 1.2em;
}
</style>

<style scoped>
.customer-value {
  font-size: 1.0rem;
  color: black;
  margin-left: 5px;
}
</style>